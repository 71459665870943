import {
	createUser,
	getUser,
	getSchedule,
	authenticateUser,
	registerAuditLog,
	getUserByPhone,
} from "@/services/users.service";

export default {
	namespaced: true,
	state: {
		userRequestStatus: "init",
		user: null,
		schedules: null,
		userSchedules: null,
		tokenData: null,
		defaultOccupations: [
			"Médico",
			"Médico - Alergia e Imunologia",
			"Médico - Cardiologista",
			"Médico - Cirurgia Vascular",
			"Médico - Clínico Geral",
			"Médico - Coloproctologista",
			"Médico - Dermatologista",
			"Médico - Endocrinologista",
			"Médico - Gastroenterologia",
			"Médico - Gastroenterologia Pediátrica",
			"Médico - Geriatra",
			"Médico - Ginecologista",
			"Médico - Hematologista",
			"Médico - Hepatologista",
			"Médico - Infectologista",
			"Médico - Medicina de Família",
			"Médico - Nefrologista",
			"Médico - Neurologista",
			"Médico - Nutrólogo",
			"Médico - Oftalmologista",
			"Médico - Ortopedista",
			"Médico - Otorrino",
			"Médico - Pediatra",
			"Médico - Pneumologista",
			"Médico - Psiquiatra",
			"Médico - Psiquiatra Infantil",
			"Médico - Reumatologista",
			"Médico - Urologista",
			"Médico de Família",
		],
		phsOccupations: ["Nutricionista", "Personal", "Psicólogo"],
		kliniOccupations: [
			"Médico - Alergia e Imunologia",
			"Médico - Cardiologista",
			"Médico - Cirurgia Vascular",
			"Médico - Clínico Geral",
			"Médico - Coloproctologista",
			"Médico - Dermatologista",
			"Médico - Endocrinologista",
			"Médico - Gastroenterologia",
			"Médico - Geriatra",
			"Médico - Ginecologista",
			"Médico - Hematologista",
			"Médico - Hepatologista",
			"Médico - Infectologista",
			"Médico - Medicina de Família",
			"Médico - Nefrologista",
			"Médico - Neurologista",
			"Médico - Nutrólogo",
			"Médico - Oftalmologista",
			"Médico - Ortopedista",
			"Médico - Otorrino",
			"Médico - Pediatra",
			"Médico - Pneumologista",
			"Médico - Psiquiatra",
			"Médico - Reumatologista",
			"Médico - Urologista",
			"Nutricionista",
			"Psicólogo",
		],
		famOccupations: ["Médico - Clínico Geral"],
		mentalOccupations: ["Psicólogo"],
	},
	getters: {
		getUser(state) {
			return state.schedules;
		},
		getUserData(state) {
			return state.user;
		},
		// STATUS
		getUserRequestStatus(state) {
			return state.userRequestStatus;
		},
		getTokenData(state) {
			return state.tokenData;
		},
		getOccupations(state) {
			if (location.hostname.includes("exercito"))
				return state.defaultOccupations;
			if (location.hostname.includes("habitossaudaveis"))
				return state.phsOccupations;
			if (location.hostname.includes("klini"))
				return state.kliniOccupations;
			if (location.hostname.includes("familhaosaude"))
				return state.famOccupations;
			if (
				location.hostname.includes("profarma") ||
				location.hostname.includes("saudemental")
			)
				return state.mentalOccupations;
			return state.defaultOccupations.concat(state.phsOccupations);
		},
		getUserSchedules(state) {
			return state.userSchedules;
		},
	},
	mutations: {
		SET_USER(state, value) {
			state.user = value;
		},
		SET_SCHEDULES(state, value) {
			state.schedules = value;
		},
		// STATUS
		USER_STATUS(state, value) {
			state.userRequestStatus = value;
		},
		SET_TOKEN_DATA(state, value) {
			state.tokenData = value;
		},
		SET_OCCUPATIONS(state, value) {
			state.defaultOccupations = value.map((item) => item.occupation);
		},
		SET_USER_SCHEDULES(state, value) {
			state.userSchedules = value;
		},
	},
	actions: {
		async createUser({ commit }, params) {
			commit("USER_STATUS", "pending");
			try {
				const { data } = await createUser(params);
				commit("SET_USER_SCHEDULES", data);
				commit("USER_STATUS", "init");
				return data;
			} catch (error) {
				commit("USER_STATUS", "init");
				throw error;
			}
		},
		async getUser({ commit }, params) {
			commit("USER_STATUS", "pending");
			try {
				const { data } = await getSchedule(params);
				commit("USER_STATUS", "init");
				commit("SET_SCHEDULES", data);
				return data;
			} catch (error) {
				commit("USER_STATUS", "init");
				throw error;
			}
		},
		async getUserData({ commit }, params) {
			commit("USER_STATUS", "pending");
			try {
				const { data } = await getUser(params);
				commit("USER_STATUS", "init");
				commit("SET_USER", data?.items);
				return data;
			} catch (error) {
				commit("USER_STATUS", "init");
				throw error;
			}
		},
		async getUserDataByPhone({ commit }, params) {
			commit("USER_STATUS", "pending");
			try {
				const { data } = await getUserByPhone(params);
				commit("USER_STATUS", "init");
				commit("SET_USER", data?.items);
				return data;
			} catch (error) {
				commit("USER_STATUS", "init");
				throw error;
			}
		},
		async authUserByCode({ commit }, params) {
			const allowedStatuses = ["Agendado", "Reagendado", "Concluído"];

			commit("USER_STATUS", "pending");
			try {
				const { data } = await authenticateUser(params);
				if (!allowedStatuses.includes(data.status) || !data.status) {
					commit("SET_TOKEN_DATA", data);
					commit("SET_OCCUPATIONS", data.speciality);
				}
				commit("USER_STATUS", "init");
				commit("SET_USER", data);
				return data;
			} catch (error) {
				commit("USER_STATUS", "init");
				throw error;
			}
		},
		// eslint-disable-next-line no-unused-vars
		createAuditLog({ commit }, params) {
			registerAuditLog(params);
		},
	},
};
