import "@/assets/_variables_clients.css";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import moment from "moment";
import Vue from "vue";
import Notifications from "vue-notification";
import VueTheMask from "vue-the-mask";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.filter("CPF", function (documentId, documentType) {
	if (!documentId) return "000.000.000-00";
	documentId =
		typeof documentId != "string" ? documentId.toString() : documentId;
	if (documentType == "J") {
		documentId = documentId.padStart(14, "0");
		documentId = documentId.replace(
			/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			"$1.$2.$3/$4-$5"
		);
	} else {
		documentId = documentId.padStart(11, "0");
		documentId = documentId.replace(
			/^(\d{3})(\d{3})(\d{3})(\d{2})/,
			"$1.$2.$3-$4"
		);
	}
	return documentId;
});

Vue.filter("firstName", function (name) {
	return name.split(" ")[0];
});

Vue.filter("camelCase", function (value) {
	if (!value) return "";
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.config.productionTip = false;

Vue.prototype.moment = moment;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueTheMask);
Vue.use(Notifications);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
