import Vue from "vue";
import VueRouter from "vue-router";

import AppLayout from "@/layouts/AppLayout.vue";
import AppLayoutEmbedded from "@/layouts/AppLayoutEmbedded.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "*",
		name: "schedule",
		redirect: { name: "error", params: { code: 404 } },
	},
	{
		path: "/",
		name: "home",
		redirect: { name: "schedule-start" },
	},
	{
		path: "/agradecimento",
		name: "thank-you",
		component: () =>
			import(/* webpackChunkName: "thank-you" */ "@/views/ThankYou.vue"),
		beforeEnter: (to, from, next) => {
			const referrer = document.referrer;
			if (
				(from?.name && from?.name?.includes("embedded")) ||
				(referrer && referrer?.includes("/int/"))
			) {
				next({
					name: "embedded-thanks",
					query: { ...to.query },
				});
			} else {
				next();
			}
		},
	},
	{
		path: "/erro/:code",
		name: "error",
		component: () =>
			import(/* webpackChunkName: "error" */ "@/views/NotFound.vue"),
	},
	{
		path: "/int",
		component: AppLayoutEmbedded,
		children: [
			{
				path: "pronto-atendimento/:cpf?",
				name: "schedule-pa",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/Schedule"
					),
			},
			{
				path: "agendar/:cpf?",
				name: "schedule-only",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/Schedule"
					),
			},
			{
				path: "auto/:cpf?",
				name: "schedule-auto",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/ScheduleAuto.vue"
					),
			},
			{
				path: "agendamento/:cpf?",
				name: "embedded-start",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/Schedule"
					),
			},
			{
				path: "reagendamento/:cpf",
				name: "embedded-reschedule",
				component: () =>
					import(
						/* webpackChunkName: "reschedule" */ "@/views/ReSchedule.vue"
					),
			},
			{
				path: "meu-agendamento/:cpf",
				name: "embedded-scheduled",
				component: () =>
					import(
						/* webpackChunkName: "scheduled-start" */ "@/views/Scheduled.vue"
					),
			},
			{
				path: "atendimento/pre-consulta/:meetCode/:cpf",
				name: "embedded-test",
				component: () =>
					import(
						/* webpackChunkName: "scheduled-test" */ "@/views/CallTest.vue"
					),
			},
			{
				path: "atendimento/pre-consulta-ob/:meetCode/:cpf",
				name: "embedded-test-overbook",
				component: () =>
					import(
						/* webpackChunkName: "embedded-test-overbook" */ "@/views/CallTestOverbook.vue"
					),
			},
			{
				path: "atendimento/sala-de-espera/:meetCode/:cpf",
				name: "embedded-overbook",
				component: () =>
					import(
						/* webpackChunkName: "embedded-overbook" */ "@/views/OverbookQueue.vue"
					),
			},
			{
				path: "atendimento/consulta/:meetCode/:cpf",
				name: "embedded-meet",
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ "@/views/SortingMeet.vue"
					),
			},
			{
				path: "agradecimento",
				name: "embedded-thanks",
				component: () =>
					import(
						/* webpackChunkName: "thank-you" */ "@/views/ThankYou.vue"
					),
			},
		],
	},
	{
		path: "/app",
		component: AppLayout,
		children: [
			{
				path: "pronto-atendimento/:cpf?",
				name: "schedule-pa",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/Schedule"
					),
			},
			{
				path: "agendar/:cpf?",
				name: "schedule-only",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/Schedule"
					),
			},
			{
				path: "auto-identificacao/:token",
				name: "auto-id-exercito",
				//redirecionar para o schedule-start passando o token como query param
				redirect: (to) => {
					const { token } = to.params;
					return {
						name: "schedule-start",
						query: { token },
					};
				},
			},
			{
				path: "auto/:cpf?",
				name: "schedule-auto",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/ScheduleAuto.vue"
					),
			},
			{
				path: "agendamento/:cpf?",
				name: "schedule-start",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/Schedule.vue"
					),
				beforeEnter: (to, from, next) => {
					const referrer = document.referrer;

					if (window.location.hostname.includes("avus")) {
						next({ name: "error", params: { code: 404 } });
					} else if (
						(from?.name && from?.name?.includes("embedded")) ||
						(referrer && referrer?.includes("/int/"))
					) {
						next({
							name: "embedded-start",
							query: { ...to.query },
						});
					} else {
						next();
					}
				},
			},
			{
				path: "agendamento-teste",
				name: "schedule-test",
				component: () =>
					import(
						/* webpackChunkName: "schedule" */ "@/views/TestSchedule.vue"
					),
			},
			{
				path: "reagendamento/:cpf",
				name: "reschedule",
				component: () =>
					import(
						/* webpackChunkName: "reschedule" */ "@/views/ReSchedule.vue"
					),
				beforeEnter: (to, from, next) => {
					const referrer = document.referrer;
					if (
						(from?.name && from?.name?.includes("embedded")) ||
						(referrer && referrer?.includes("/int/"))
					) {
						next({
							name: "embedded-reschedule",
							params: { ...to.params },
							query: { ...to.query },
						});
					} else {
						next();
					}
				},
			},
			{
				path: "reagendamento-teste/:cpf",
				name: "reschedule-test",
				component: () =>
					import(
						/* webpackChunkName: "reschedule" */ "@/views/ReSchedule.vue"
					),
			},
			{
				path: "meu-agendamento/:cpf",
				name: "scheduled-start",
				component: () =>
					import(
						/* webpackChunkName: "scheduled-start" */ "@/views/Scheduled.vue"
					),
				beforeEnter: (to, from, next) => {
					const referrer = document.referrer;
					if (
						(from?.name && from?.name?.includes("embedded")) ||
						(referrer && referrer?.includes("/int/"))
					) {
						next({
							name: "embedded-scheduled",
							params: { ...to.params },
						});
					} else {
						next();
					}
				},
			},
			{
				path: "meu-agendamento-teste/:cpf",
				name: "scheduled-start-test",
				component: () =>
					import(
						/* webpackChunkName: "scheduled-start" */ "@/views/Scheduled.vue"
					),
			},
			{
				path: "atendimento/sala-de-espera/:meetCode/:cpf",
				name: "overbook-queue",
				component: () =>
					import(
						/* webpackChunkName: "overbook-queue" */ "@/views/OverbookQueue.vue"
					),
				beforeEnter: (to, from, next) => {
					const referrer = document.referrer;
					if (
						(from?.name && from?.name?.includes("embedded")) ||
						(referrer && referrer?.includes("/int/"))
					) {
						next({
							name: "embedded-overbook",
							params: {
								...to.params,
							},
						});
					} else {
						next();
					}
				},
			},
			{
				path: "atendimento/pre-consulta/:meetCode/:cpf",
				name: "scheduled-test",
				component: () =>
					import(
						/* webpackChunkName: "scheduled-test" */ "@/views/CallTest.vue"
					),
				beforeEnter: (to, from, next) => {
					const referrer = document.referrer;
					if (
						(from?.name && from?.name?.includes("embedded")) ||
						(referrer && referrer?.includes("/int/"))
					) {
						next({
							name: "embedded-test",
							params: {
								...to.params,
							},
						});
					} else {
						next();
					}
				},
			},
			{
				path: "atendimento/pre-consulta-ob/:meetCode/:cpf",
				name: "scheduled-test-overbook",
				component: () =>
					import(
						/* webpackChunkName: "scheduled-test-overbook" */ "@/views/CallTestOverbook.vue"
					),
				beforeEnter: (to, from, next) => {
					const referrer = document.referrer;
					if (
						(from?.name && from?.name?.includes("embedded")) ||
						(referrer && referrer?.includes("/int/"))
					) {
						next({
							name: "embedded-test-overbook",
							params: {
								...to.params,
							},
						});
					} else {
						next();
					}
				},
			},
			{
				path: "atendimento/consulta/:meetCode/:cpf",
				name: "meet",
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ "@/views/SortingMeet.vue"
					),
				beforeEnter: (to, from, next) => {
					const referrer = document.referrer;
					if (
						(from?.name && from?.name?.includes("embedded")) ||
						(referrer && referrer?.includes("/int/"))
					) {
						next({
							name: "embedded-meet",
							params: {
								...to.params,
							},
						});
					} else {
						next();
					}
				},
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

router.beforeEach((to, _, next) => {
	// const isAuthenticated = localStorage.getItem('accessToken')

	return next();
});

export default router;
