import Http from './index';
import HttpPartner from './user';
import axios from 'axios';

// eslint-disable-next-line
import { serialize } from '@/util';

export const calendar = (params) => Http.get(`/calendar${serialize(params)}`);
export const fetchDoctors = (occupation) => Http.get(`/doctors/${occupation}?limit=1000`);
export const fetchOccupations = () => Http.get(`/tmed/config`);
export const schedule = (params) => Http.post(`/calendar`, params);
export const reschedule = (params) => Http.put(`/calendar`, params);
export const cancel = (params) => Http.delete(`/calendar/${params.cpf}/${params.scheduleId}`);
// QUEUE
export const enterQueue = (params) => Http.post(`/queue/wait`, params);
export const cancelQueue = (params) => Http.delete(`/queue/${params}`);
export const checkQueue = (params) => Http.get(`/queue${serialize(params)}`);
export const avaliation = (payload) =>
  HttpPartner.post(`meetings/avaliation/meets-code/${payload.meets}`, {
    ...payload,
  });
export const sendFile = (payload) => Http.post(`/create/patient/docs`, payload);

// OVERBOOK QUEUE
const hostname = location.hostname;
const isDevelopmentEnvironment = ['localhost', 'dev-', 'uat-', 'partner-'].some((env) => hostname.includes(env));

let queueEnvironment = 'https://telemedicina.medex.net.br/v1';

if (isDevelopmentEnvironment) {
  queueEnvironment = hostname.includes('localhost')
    ? 'https://dev-telemedicina.medex.net.br/v1'
    : `https://${location.host}/v1`;
}

export const enterOverbookQueue = (params) => axios.post(`${queueEnvironment}/doctor/queue/enter`, params);
export const quitOverbookQueue = (params) =>
  axios.delete(`${queueEnvironment}/doctor/queue/leave/${params.cpf}/${params.doctorId}`);

let wsEnviroment = 'wss://0331st63ck.execute-api.us-east-1.amazonaws.com/prd';
if (location.hostname.includes('localhost') || location.hostname.includes('dev-')) {
  wsEnviroment = 'wss://iqr2gw7bmc.execute-api.us-east-1.amazonaws.com/dev';
} else if (location.hostname.includes('uat-')) {
  wsEnviroment = 'wss://pidlh2k6y3.execute-api.us-east-1.amazonaws.com/uat ';
} else if (location.hostname.includes('partner-')) {
  wsEnviroment = 'wss://pidlh2k6y3.execute-api.us-east-1.amazonaws.com/ppr';
}
export const wsUrl = wsEnviroment;
