<template>
	<div id="app">
		<transition name="fade" mode="out-in">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "steps-layout",
	data() {
		return {};
	},
	mounted() {},
	computed: {
		...mapGetters({}),
	},
	methods: {
		...mapActions({}),
	},
};
</script>

<style lang="scss">
@import "@/assets/scss/variables";

#app {
	display: flex;
	height: 100%;
	flex-direction: column;
}
</style>
