import axios from "axios";

let url = "https://" + location.host + "/v1";

const hostname = process.env.VUE_APP_URL_DEV;

if (hostname) url = hostname + "/v1";

console.log(url);

const client = axios.create({
	baseURL: url,
	// headers: {
	// 	"Content-Type": "application/json",
	// 	"Accept": "application/json",
	// 	'Access-Control-Allow-Origin': '*',
	// 	"Content-Language": `pt-BR`
	// }
});

client.interceptors.request.use(
	(config) => {
		// Do something before request is sent
		const accessToken = localStorage.getItem("token");
		// eslint-disable-next-line no-param-reassign
		if (accessToken) config.headers.Authorization = `${accessToken}`;
		return config;
	},
	(error) => Promise.reject(error)
);

client.interceptors.response.use(
	(response) => response,
	(error) => {
		switch (error.response?.status) {
			case 403:
				console.error("403");
				break;
			default:
				break;
		}
		return Promise.reject(error);
	}
);

export default client;
