import {
  avaliation,
  calendar,
  cancel,
  cancelQueue,
  checkQueue,
  enterQueue,
  reschedule,
  schedule,
  fetchDoctors,
  fetchOccupations,
  sendFile,
  enterOverbookQueue,
  quitOverbookQueue,
} from '@/services/schedule.service';

export default {
  namespaced: true,
  state: {
    calendar: {},
    calendarStatus: 'init',
    saveScheduleStatus: 'init',
    userQueue: null,
    queueStatus: 'init',
    saveFileStatus: 'init',
    doctors: [],
    doctorStatus: 'init',
    occupations: [],
    occupationsStatus: 'init',
    // OVERBOOK QUEUE
    enterOverbookStatus: 'init',
    quitOverbookStatus: 'init',
  },
  getters: {
    // SACHEDULE
    getCalendar(state) {
      return state.calendar;
    },
    // QUEUE
    getUserQueue(state) {
      return state.userQueue;
    },
    // STATUS
    getCalendarResquestStatus(state) {
      return state.calendarStatus;
    },
    getQueueStatus(state) {
      return state.queueStatus;
    },
    getSaveScheduleStatus(state) {
      return state.saveScheduleStatus;
    },
    getDoctors(state) {
      return state.doctors;
    },
    getSaveFileStatus(state) {
      return state.saveFileStatus;
    },
    getDoctorStatus(state) {
      return state.doctorStatus;
    },
    getOccupations(state) {
      return state.occupations;
    },
    getOccupationsStatus(state) {
      return state.occupationsStatus;
    },
    getOverbookQueueStatus(state) {
      return state.enterOverbookStatus;
    },
    getQuitOverbookQueueStatus(state) {
      return state.quitOverbookStatus;
    },
  },
  mutations: {
    // CALENDAR
    SET_CALENDAR(state, value) {
      state.calendar = value;
    },
    // QUEUE
    SET_QUEUE(state, value) {
      state.userQueue = value;
    },
    // STATUS
    QUEUE_STATUS(state, value) {
      state.queueStatus = value;
    },
    CALENDAR_STATUS(state, value) {
      state.calendarStatus = value;
    },
    SAVE_SCHEDULE_STATUS(state, value) {
      state.saveScheduleStatus = value;
    },
    SET_DOCTORS(state, value) {
      state.doctors = value;
    },
    SAVE_FILE_STATUS(state, value) {
      state.saveFileStatus = value;
    },
    DOCTOR_STATUS(state, value) {
      state.doctorStatus = value;
    },
    SET_OCCUPATIONS(state, value) {
      state.occupations = value;
    },
    SET_OCCUPATIONS_STATUS(state, value) {
      state.occupationsStatus = value;
    },
    ENTER_OVERBOOK_STATUS(state, value) {
      state.enterOverbookStatus = value;
    },
    QUIT_OVERBOOK_STATUS(state, value) {
      state.quitOverbookStatus = value;
    },
  },
  actions: {
    async getScheduleCalendar({ commit }, params) {
      commit('CALENDAR_STATUS', 'pending');
      try {
        const { data } = await calendar(params);

        if (Object.keys(data.item).length <= 0) {
          commit('SET_CALENDAR', { noHour: true });
        } else {
          let tempNewObj = {};

          for (let day in data.item) {
            let tempObjKey = day.split(' ')[0];

            if (!(tempObjKey in tempNewObj)) tempNewObj[tempObjKey] = { hours: [] };

            tempNewObj[tempObjKey]['hours'].push(day.split(' ')[1]);
          }
          commit('SET_CALENDAR', {
            itens: data.item,
            formated: tempNewObj,
          });
          commit('CALENDAR_STATUS', 'init');
          return data;
        }
      } catch (error) {
        commit('CALENDAR_STATUS', 'init');
        commit('SET_CALENDAR', {});

        throw error;
      }
    },
    async scheduleDateHour({ commit }, params) {
      commit('SAVE_SCHEDULE_STATUS', 'pending');
      try {
        const data = await schedule(params);
        commit('SAVE_SCHEDULE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('SAVE_SCHEDULE_STATUS', 'init');
        throw error;
      }
    },
    async rescheduleDateHour({ commit }, params) {
      commit('SAVE_SCHEDULE_STATUS', 'pending');
      try {
        const data = await reschedule(params);
        commit('SAVE_SCHEDULE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('SAVE_SCHEDULE_STATUS', 'init');
        throw error;
      }
    },
    async cancelDateHour({ commit }, params) {
      commit('SAVE_SCHEDULE_STATUS', 'pending');
      try {
        const data = await cancel(params);
        commit('SAVE_SCHEDULE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('SAVE_SCHEDULE_STATUS', 'init');
        throw error;
      }
    },
    // QUEUE
    async enterQueue({ commit }, params) {
      commit('SAVE_SCHEDULE_STATUS', 'pending');
      try {
        const { data } = await enterQueue(params);
        commit('SET_QUEUE', data);
        commit('SAVE_SCHEDULE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('SAVE_SCHEDULE_STATUS', 'init');
        throw error;
      }
    },
    async enterQueueAuto({ commit }, params) {
      commit('QUEUE_STATUS', 'pending');
      try {
        const { data } = await enterQueue(params);
        commit('SET_QUEUE', data);
        commit('QUEUE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('QUEUE_STATUS', 'init');
        throw error;
      }
    },
    async cancelQueue({ commit }, params) {
      commit('SAVE_SCHEDULE_STATUS', 'pending');
      try {
        const data = await cancelQueue(params);
        commit('SAVE_SCHEDULE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('SAVE_SCHEDULE_STATUS', 'init');
        throw error;
      }
    },
    async checkQueue({ commit }, params) {
      commit('QUEUE_STATUS', 'pending');
      try {
        const { data } = await checkQueue(params);
        commit('SET_QUEUE', data);
        commit('QUEUE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('QUEUE_STATUS', 'init');
        throw error;
      }
    },
    async avaliation({ commit }, param) {
      commit('REQUEST_SAVE_STATUS', 'pending');
      try {
        await avaliation(param);
        commit('REQUEST_SAVE_STATUS', 'init');
      } catch (error) {
        commit('REQUEST_SAVE_STATUS', 'init');
        throw error;
      }
    },
    setQueuePosition({ commit }, value) {
      commit('SET_QUEUE', value);
    },
    async getDoctors({ commit }, params) {
      commit('DOCTOR_STATUS', 'pending');
      try {
        const { data } = await fetchDoctors(params);
        commit('SET_DOCTORS', data);
        commit('DOCTOR_STATUS', 'init');
        return data;
      } catch (error) {
        commit('DOCTOR_STATUS', 'init');
        throw error;
      }
    },
    async saveFile({ commit }, params) {
      commit('SAVE_FILE_STATUS', 'pending');
      try {
        const data = await sendFile(params);
        commit('SAVE_FILE_STATUS', 'init');
        return data;
      } catch (error) {
        commit('SAVE_FILE_STATUS', 'init');
        throw error;
      }
    },
    async listOccupations({ commit }) {
      commit('SET_OCCUPATIONS_STATUS', 'pending');
      try {
        const { data } = await fetchOccupations();
        const sortedData = data?.item?.occupations.sort((a, b) => a.localeCompare(b));
        commit('SET_OCCUPATIONS', sortedData || []);
        commit('SET_OCCUPATIONS_STATUS', 'init');
        return data;
      } catch (error) {
        commit('SET_OCCUPATIONS_STATUS', 'init');
        throw error;
      }
    },
    async handleEnterOverbookQueue({ commit }, params) {
      commit('ENTER_OVERBOOK_STATUS', 'pending');
      try {
        const res = await enterOverbookQueue(params);
        commit('ENTER_OVERBOOK_STATUS', 'init');
        return res;
      } catch (error) {
        commit('ENTER_OVERBOOK_STATUS', 'init');
        throw error;
      }
    },
    async handleQuitOverbookQueue({ commit }, params) {
      commit('QUIT_OVERBOOK_STATUS', 'pending');
      try {
        await quitOverbookQueue(params);
        commit('QUIT_OVERBOOK_STATUS', 'init');
      } catch (error) {
        commit('QUIT_OVERBOOK_STATUS', 'init');
        throw error;
      }
    },
  },
};
