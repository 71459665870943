export default {
	namespaced: true,
	state: {},
	getters: {
		isExercito() {
			return location.hostname.includes("exercito");
		},
		isMapma() {
			return location.hostname.includes("mapma");
		},
		isEmbedded() {
			return location.pathname.includes("/int/");
		},
		isBrokers() {
			return location.hostname.includes("brks");
		},
		isTemSaude() {
			return location.hostname.includes("temsaude");
		},
		isPHS() {
			return location.hostname.includes("habitossaudaveis");
		},
		isTest() {
			return location.pathname.includes("-teste");
		},
		isProfarma() {
			return location.hostname.includes("profarma");
		},
		isNamu() {
			return location.hostname.includes("namu");
		},
		isSaudeMental() {
			return location.hostname.includes("saudemental");
		},
	},
	mutations: {},
	actions: {},
};
