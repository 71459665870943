import axios from "axios";

let baseURL =
	process.env.NODE_ENV === "development"
		? process.env.VUE_APP_ADMIN_URL_DEV
		: process.env.VUE_APP_ADMIN_URL_PROD;

let hostname;
switch (location.host) {
	case "dev-telemedicina.medex.net.br":
		hostname = "https://dev-partner.medexapi.com";
		break;
	case "stg-telemedicina.medex.net.br":
		hostname = "https://dev-partner.medexapi.com";
		break;
	case "uat-telemedicina.medex.net.br":
		hostname = "https://demo-partner.medexapi.com";
		break;
	case "partner-telemedicina.medex.net.br":
		hostname = "https://ppr-partner.medexapi.com";
		break;
	case "partner-klinitelemedicina.medex.net.br":
		hostname = "https://ppr-partner.medexapi.com";
		break;
	case "telemedicina.medex.net.br":
		hostname = "https://partner.medexapi.com";
		break;
	default:
		hostname = "https://partner.medexapi.com";
		break;
}

if (!baseURL) baseURL = hostname;

const client = axios.create({
	baseURL: baseURL,
});

client.interceptors.request.use(
	(config) => {
		// Do something before request is sent
		const accessToken = localStorage.getItem("token");
		// eslint-disable-next-line no-param-reassign
		if (accessToken) config.headers.Authorization = `${accessToken}`;
		return config;
	},
	(error) => Promise.reject(error)
);

client.interceptors.response.use(
	(response) => response,
	(error) => {
		switch (error.response?.status) {
			case 403:
				window.location.href = "/";
				break;
			default:
				break;
		}
		return Promise.reject(error);
	}
);

export default client;
